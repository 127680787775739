import "./Home.css";
import {
  Box,
  Button,
  Card,
  CardContent,
  CardMedia,
  List,
  ListItem,
  Typography,
  TextField,
} from "@mui/material";
import React, { useState, useEffect } from "react";
import Grid from "@mui/material/Grid";

// Assets
import BANNER from "../../Assets/s1.jpg";
import SIDEBG from "../../Assets/s-side.png";
import AB from "../../Assets/ab.jpg";
import LAW from "../../Assets/law.png";
import FAMILY from "../../Assets/family.png";
import COUCRT from "../../Assets/courts.png";
import BURGLAR from "../../Assets/burglar.png";
import SECRITY from "../../Assets/cyber-security.png";
import SHUFFLE from "../../Assets/shuffle.png";

// Form
import { useForm } from "react-hook-form";

// Animation
import AOS from "aos";
import "aos/dist/aos.css";

// MUI Icons
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import GavelIcon from "@mui/icons-material/Gavel";
import BalanceIcon from "@mui/icons-material/Balance";
import DoneIcon from "@mui/icons-material/Done";
import ThumbUpOffAltIcon from "@mui/icons-material/ThumbUpOffAlt";
import ChatBubbleOutlineIcon from "@mui/icons-material/ChatBubbleOutline";
import LightbulbIcon from "@mui/icons-material/Lightbulb";
import WorkIcon from "@mui/icons-material/Work";
import FormatQuoteIcon from "@mui/icons-material/FormatQuote";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import CallIcon from "@mui/icons-material/Call";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import SendIcon from "@mui/icons-material/Send";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";

// Slider
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

// Responsive-Carousel
import "react-responsive-carousel/lib/styles/carousel.min.css";

// ScrollToTop
import ScrollToTop from "react-scroll-to-top";

// Count Up
import CountUp from "react-countup";
import ScrollTrigger from "react-scroll-trigger";

function Home() {
  useEffect(() => {
    AOS.init();
  });

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 5,
    responsive: [
      {
        breakpoint: 1441,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
        },
      },
      {
        breakpoint: 1025,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const testimonial = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 2,
    responsive: [
      {
        breakpoint: 1440,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const [counterOn, SetCounterOn] = useState(false);

  // Form Validation
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const portfolioImg = [
    { img: "Images/prt1.jpg" },
    { img: "Images/prt2.jpg" },
    { img: "Images/prt3.jpg" },
    { img: "Images/prt4.jpg" },
    { img: "Images/prt5.jpg" },
    { img: "Images/prt6.jpg" },
    { img: "Images/prt7.jpg" },
    { img: "Images/prt8.jpg" },
    { img: "Images/prt9.jpg" },
    { img: "Images/prt10.jpg" },
  ];

  const testimonialImg = [
    { img: "Images/tst1.jpg" },
    { img: "Images/tst2.jpg" },
    { img: "Images/tst2.jpg" },
    { img: "Images/tst1.jpg" },
  ];

  return (
    <Box>
      {/* Banner Section */}
      <Box sx={{ overflow: "hidden", position: "relative" }}>
        <Box
          sx={{
            backgroundImage: `url(${BANNER})`,
            backgroundSize: "cover",
            height: "90vh",
            filter: "brightness(0.5)",
          }}
          className="zoom-in-out"
        ></Box>
        <Grid
          container
          maxWidth="lg"
          margin="auto"
          sx={{
            color: "#fff",
            position: "absolute",
            top: "0vh",
            left: 0,
            right: 0,
          }}
          pt={{ xl: 15, md: 0, sm: 3, xs: 15 }}
        >
          <Grid
            item
            xl={6}
            lg={6}
            pt={{ xl: 10, lg: 20, md: 25, sm: 20, xs: 5 }}
            data-aos="fade-right"
            data-aos-duration="3000"
            px={{ lg: 0, md: 5, sm: 5, xs: 2 }}
          >
            <Typography variant="h6" className="home_sub_head">
              Legal solutions for Business
            </Typography>
            <Typography
              variant="h3"
              className="home_head"
              pt={{ xl: 5, lg: 5, md: 5, sm: 5, xs: 5 }}
            >
              We are recognized <Box component="br"></Box> by honorable awards
            </Typography>
            <Typography
              className="home_sub_head"
              variant="subtitle1"
              pt={{ xl: 5, lg: 5, md: 5, sm: 5, xs: 5 }}
            >
              Our specialists share skills and resources to obtain optimal
              results for our new clients A full service law firm in New York.
            </Typography>
            <Box
              pt={{ xl: 5, lg: 8, md: 8, sm: 8, xs: 5 }}
              sx={{ position: "relative" }}
            >
              <Button
                variant="contained"
                size="large"
                className="primary-bg-color white-color home_btns"
                sx={{ padding: "15px 20px" }}
              >
                OUR EXPERT TEAM
                <Box className="home_btn_1"></Box>
              </Button>
            </Box>
          </Grid>
          <Grid
            item
            xl={6}
            lg={6}
            sx={{ display: { lg: "block", xs: "none" } }}
            data-aos="fade-left"
            data-aos-duration="3000"
          >
            <Box
              component="img"
              src={SIDEBG}
              pt={{ xl: 3, lg: 21 }}
              height="70vh"
            ></Box>
          </Grid>
        </Grid>
      </Box>
      {/* Feature Section */}
      <Box sx={{ marginTop: -5 }}>
        <Grid container maxWidth="lg" margin="auto">
          <Grid
            item
            xl={4}
            lg={4}
            md={4}
            sm={12}
            xs={12}
            px={{ xl: 2, lg: 2, md: 3, sm: 3, xs: 2 }}
            pt={{ md: 0, sm: 8, xs: 8 }}
            data-aos="fade-up"
            data-aos-duration="3000"
          >
            <Card className="feature_card">
              <CardMedia>
                <AccountBalanceIcon
                  sx={{ color: "#c6ac82", fontSize: "50px" }}
                />
              </CardMedia>
              <CardContent>
                <Typography variant="h6" className="feature_head">
                  High ethical standard
                </Typography>
                <Typography
                  variant="body1"
                  sx={{ paddingTop: 2, color: "#bebebe" }}
                >
                  A full service law firm in London that has been recognised
                  internationally with specialisation in Commercial.
                </Typography>
              </CardContent>
            </Card>
          </Grid>
          <Grid
            item
            xl={4}
            lg={4}
            md={4}
            sm={12}
            xs={12}
            px={{ xl: 2, lg: 2, md: 3, sm: 3, xs: 2 }}
            pt={{ md: 0, sm: 3, xs: 3 }}
            data-aos="fade-up"
            data-aos-duration="3000"
          >
            <Card className="feature_card">
              <CardMedia>
                <GavelIcon sx={{ color: "#c6ac82", fontSize: "50px" }} />
              </CardMedia>
              <CardContent>
                <Typography variant="h6" className="feature_head">
                  Compliant HR policy{" "}
                </Typography>
                <Typography
                  variant="body1"
                  sx={{ paddingTop: 2, color: "#bebebe" }}
                >
                  A full service law firm in London that has been recognised
                  internationally with specialisation in Commercial.
                </Typography>
              </CardContent>
            </Card>
          </Grid>
          <Grid
            item
            xl={4}
            lg={4}
            md={4}
            sm={12}
            xs={12}
            sx={{ zIndex: 1 }}
            px={{ xl: 2, lg: 2, md: 3, sm: 3, xs: 2 }}
            pt={{ md: 0, sm: 3, xs: 3 }}
            data-aos="fade-up"
            data-aos-duration="3000"
          >
            <Card className="feature_card">
              <CardMedia>
                <BalanceIcon sx={{ color: "#c6ac82", fontSize: "50px" }} />
              </CardMedia>
              <CardContent>
                <Typography variant="h6" className="feature_head">
                  Specialised lawyers{" "}
                </Typography>
                <Typography
                  variant="body1"
                  sx={{ paddingTop: 2, color: "#bebebe" }}
                >
                  A full service law firm in London that has been recognised
                  internationally with specialisation in Commercial.
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Box>
      {/* Home About Section */}
      <Box sx={{ padding: "100px 0px", overflow: "hidden" }}>
        <Grid container maxWidth="lg" margin="auto">
          <Grid
            item
            xl={6}
            lg={6}
            md={6}
            sm={12}
            xs={12}
            data-aos="fade-right"
            data-aos-duration="3000"
          >
            <Box
              component="img"
              src={AB}
              width="-webkit-fill-available"
              p={{ md: 2, sm: 2, xs: 2 }}
              className="hover_rotate"
            ></Box>
          </Grid>
          <Grid
            item
            xl={6}
            lg={6}
            md={6}
            sm={12}
            xs={12}
            sx={{ padding: 2 }}
            data-aos="fade-left"
            data-aos-duration="3000"
          >
            <Box>
              <Box>
                <Typography variant="h3" className="homePageHead">
                  We are Professional Law Firm in{" "}
                  <Box component="span" sx={{ color: "#c6ac82" }}>
                    USA
                  </Box>
                  .
                </Typography>
              </Box>
              <Box sx={{ paddingTop: 4 }}>
                <Typography variant="body1" color="#343434">
                  BDLP is one of the leading multi-disciplinary law firms in
                  Dhaka, Bangladesh managed by lawyers in and around Dhaka. The
                  law firm is one of the top law firms in Dhaka and represents
                  both foreign and local clients. The firm operates as a
                  partnership of several leading practicing follow the leading
                  multi-disciplinary .
                </Typography>
              </Box>
              <Box>
                <List>
                  <ListItem sx={{ padding: "10px 0px 0px 0px" }}>
                    <Box px={{ xl: 1, lg: 1, md: 1, sm: 1, xs: 1 }}>
                      <DoneIcon sx={{ color: "#c6ac82" }} />
                    </Box>
                    Physical and online resources
                  </ListItem>
                  <ListItem sx={{ padding: "10px 0px 0px 0px" }}>
                    <Box px={{ xl: 1, lg: 1, md: 1, sm: 1, xs: 1 }}>
                      <DoneIcon sx={{ color: "#c6ac82" }} />
                    </Box>
                    Probably the largest law firm
                  </ListItem>
                  <ListItem sx={{ padding: "10px 0px 0px 0px" }}>
                    <Box px={{ xl: 1, lg: 1, md: 1, sm: 1, xs: 1 }}>
                      <DoneIcon sx={{ color: "#c6ac82" }} />
                    </Box>
                    Practical Commercial Solution
                  </ListItem>
                </List>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Box>
      {/* Practice Section */}
      <Box sx={{ padding: "100px 0px", backgroundColor: "#eee" }}>
        <Box sx={{ textAlign: "center" }}>
          <Typography variant="h3" className="homePageHead">
            We assign{" "}
            <Box component="span" sx={{ color: "#c6ac82" }}>
              {" "}
              30+{" "}
            </Box>{" "}
            practice
            <Box component="br"></Box> areas for clients.
          </Typography>
        </Box>
        <Grid container maxWidth="lg" margin="auto" sx={{ paddingTop: 5 }}>
          <Grid
            item
            xl={4}
            lg={4}
            md={6}
            sm={12}
            xs={12}
            data-aos="fade-up"
            data-aos-duration="3000"
            px={{ lg: 0, md: 2, sm: 3, xs: 3 }}
            pt={{ md: 2, sm: 3, xs: 3 }}
          >
            <Card className="practice_card">
              <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                <Typography variant="h6" className="practice_card_head">
                  Family & Child <Box component="br"></Box> Law service
                </Typography>
                <CardMedia>
                  <Box
                    component="img"
                    src={FAMILY}
                    className="rotate_img"
                    height="50px"
                  ></Box>
                  {/* <Diversity3Icon sx={{ color: "#c6ac82", fontSize: "50px" }} /> */}
                </CardMedia>
              </Box>
              <Box>
                <Typography variant="body1" sx={{ paddingTop: 2 }}>
                  A full service law firm in London that has been recognised
                  internationally with specialisation in Commercial.
                </Typography>
              </Box>
            </Card>
          </Grid>
          <Grid
            item
            xl={4}
            lg={4}
            md={6}
            sm={12}
            xs={12}
            data-aos="fade-up"
            data-aos-duration="3000"
            px={{ xl: 3, lg: 2, md: 2, sm: 3, xs: 3 }}
            pt={{ md: 2, sm: 3, xs: 3 }}
          >
            <Card className="practice_card">
              <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                <Typography variant="h6" className="practice_card_head">
                  Corporate and <Box component="br"></Box> Finance Law
                </Typography>
                <CardMedia>
                  {/* <AccountBalanceIcon
                    sx={{ color: "#c6ac82", fontSize: "50px" }}
                  /> */}
                  <Box
                    component="img"
                    src={COUCRT}
                    className="rotate_img"
                    height="50px"
                  ></Box>
                </CardMedia>
              </Box>
              <Box>
                <Typography variant="body1" sx={{ paddingTop: 2 }}>
                  A full service law firm in London that has been recognised
                  internationally with specialisation in Commercial.
                </Typography>
              </Box>
            </Card>
          </Grid>
          <Grid
            item
            xl={4}
            lg={4}
            md={6}
            sm={12}
            xs={12}
            data-aos="fade-up"
            data-aos-duration="3000"
            px={{ xl: 0, lg: 0, md: 2, sm: 3, xs: 3 }}
            pt={{ xl: 2, lg: 2, md: 2, sm: 3, xs: 3 }}
          >
            <Card className="practice_card">
              <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                <Typography variant="h6" className="practice_card_head">
                  rxer Crime Law <Box component="br"></Box> services
                </Typography>
                <CardMedia>
                  {/* <VoiceOverOffIcon
                    sx={{ color: "#c6ac82", fontSize: "50px" }}
                  /> */}
                  <Box
                    component="img"
                    src={BURGLAR}
                    className="rotate_img"
                    height="50px"
                  ></Box>
                </CardMedia>
              </Box>
              <Box>
                <Typography variant="body1" sx={{ paddingTop: 2 }}>
                  A full service law firm in London that has been recognised
                  internationally with specialisation in Commercial.
                </Typography>
              </Box>
            </Card>
          </Grid>
          <Grid
            item
            xl={4}
            lg={4}
            md={6}
            sm={12}
            xs={12}
            data-aos="fade-up"
            data-aos-duration="3000"
            pt={{ xl: 4, lg: 3, md: 2, sm: 3, xs: 3 }}
            px={{ lg: 0, md: 2, sm: 3, xs: 3 }}
          >
            <Card className="practice_card">
              <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                <Typography variant="h6" className="practice_card_head">
                  Cross Border <Box component="br"></Box> Law service
                </Typography>
                <CardMedia>
                  {/* <AdminPanelSettingsIcon
                    sx={{ color: "#c6ac82", fontSize: "50px" }}
                  /> */}
                  <Box
                    component="img"
                    src={SECRITY}
                    className="rotate_img"
                    height="50px"
                  ></Box>
                </CardMedia>
              </Box>
              <Box>
                <Typography variant="body1" sx={{ paddingTop: 2 }}>
                  A full service law firm in London that has been recognised
                  internationally with specialisation in Commercial.
                </Typography>
              </Box>
            </Card>
          </Grid>
          <Grid
            item
            xl={4}
            lg={4}
            md={6}
            sm={12}
            xs={12}
            data-aos="fade-up"
            data-aos-duration="3000"
            px={{ xl: 3, lg: 2, md: 2, sm: 3, xs: 3 }}
            pt={{ xl: 4, lg: 3, md: 2, sm: 3, xs: 3 }}
          >
            <Card className="practice_card">
              <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                <Typography variant="h6" className="practice_card_head">
                  Foreign Business Investment law
                </Typography>
                <CardMedia>
                  <Box
                    component="img"
                    src={LAW}
                    className="rotate_img"
                    height="50px"
                  ></Box>
                  {/* <ShuffleIcon sx={{ color: "#c6ac82", fontSize: "50px" }} /> */}
                </CardMedia>
              </Box>
              <Box>
                <Typography variant="body1" sx={{ paddingTop: 2 }}>
                  A full service law firm in London that has been recognised
                  internationally with specialisation in Commercial.
                </Typography>
              </Box>
            </Card>
          </Grid>
          <Grid
            item
            xl={4}
            lg={4}
            md={6}
            sm={12}
            xs={12}
            data-aos="fade-up"
            data-aos-duration="3000"
            px={{ xl: 0, lg: 0, md: 2, sm: 3, xs: 3 }}
            pt={{ xl: 4, lg: 3, md: 2, sm: 3, xs: 3 }}
          >
            <Card className="practice_card">
              <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                <Typography variant="h6" className="practice_card_head">
                  Cyber Crime <Box component="br"></Box> Law service
                </Typography>
                <CardMedia>
                  {/* <GavelIcon sx={{ color: "#c6ac82", fontSize: "50px" }} /> */}
                  <Box
                    component="img"
                    src={SHUFFLE}
                    className="rotate_img"
                    height="50px"
                  ></Box>
                </CardMedia>
              </Box>
              <Box>
                <Typography variant="body1" sx={{ paddingTop: 2 }}>
                  A full service law firm in London that has been recognised
                  internationally with specialisation in Commercial.
                </Typography>
              </Box>
            </Card>
          </Grid>
        </Grid>
        <Box
          sx={{ paddingTop: 5, textAlign: "center", position: "relative" }}
          data-aos="fade-up"
          data-aos-duration="3000"
        >
          <Button
            variant="contained"
            size="large"
            className="secondary-bg-color white-color home_btn primary-bg-color-hover"
            sx={{ padding: "15px 20px" }}
          >
            More Services
            <Box className="home_btn_2"></Box>
          </Button>
        </Box>
      </Box>
      {/* Portfolio Section */}
      <Box sx={{ padding: "100px 0px 50px 0px", overflow: "hidden" }}>
        <Box sx={{ textAlign: "center" }}>
          <Typography variant="h3" className="homePageHead">
            We have shown Our Resent
            <Box component="br"></Box>
            <Box component="span" sx={{ color: "#c6ac82" }}>
              Case Studies.
            </Box>
          </Typography>
        </Box>
        <Box
          sx={{ typography: "body1", paddingTop: 5, position: "relative" }}
          className="carousel-container"
        >
          <Slider {...settings}>
            {portfolioImg.map((item, i) => {
              return (
                <Box className="carousel-item" key={i}>
                  <Box
                    component="i"
                    className="draw"
                    sx={{
                      position: "absolute",
                      top: "-5px",
                      padding: "138px 9vw",
                      zIndex: 1,
                      cursor: "pointer",
                    }}
                  ></Box>
                  <Box
                    component="img"
                    src={item.img}
                    sx={{ margin: "auto" }}
                    className="port_img"
                  ></Box>
                  <Box className="overlay">
                    <Box data-aos="fade-left" data-aos-duration="3000">
                      <Typography variant="caption" className="about_sec_title">
                        criminal case
                      </Typography>
                      <Typography variant="h6">Donald Car Case</Typography>
                    </Box>
                    <Box
                      className="portfolio_icon_box"
                      data-aos="fade-right"
                      data-aos-duration="3000"
                    >
                      <ArrowForwardIcon />
                    </Box>
                  </Box>
                </Box>
              );
            })}
          </Slider>
        </Box>
      </Box>
      {/* Counter Section */}
      <Box
        sx={{
          padding: "100px 0px 0px 0px",
          textAlign: "center",
          position: "relative",
        }}
      >
        <ScrollTrigger
          onEnter={() => SetCounterOn(true)}
          onExit={() => SetCounterOn(false)}
        >
          <Box
            sx={{
              backgroundImage: `url(${BANNER})`,
              backgroundAttachment: "fixed",
              backgroundSize: "cover",
              filter: "brightness(0.2)",
              padding: "15vh 0px",
            }}
            className="counter_section"
          ></Box>
          <Grid
            container
            maxWidth="lg"
            className="counter_grid"
            sx={{
              margin: "auto",
              color: "#fff",
              left: 0,
              right: 0,
              top: "20vh",
              position: "absolute",
            }}
            px={{ lg: 0, md: 5, sm: 5, xs: 6 }}
          >
            <Grid item xl={3} lg={3} md={6} sm={6} xs={12}>
              <Box sx={{ position: "relative" }}>
                <Box className="count_icon_1">
                  <ThumbUpOffAltIcon className="count_icon" />
                </Box>
                {counterOn && <CountUp end={1038} className="count_count" />}
                <Typography variant="h6" className="count_head">
                  Satisfied Customers
                </Typography>
              </Box>
            </Grid>
            <Grid item xl={3} lg={3} md={6} sm={6} xs={12}>
              <Box sx={{ position: "relative" }} pt={{ sm: 0, xs: 6 }}>
                <Box className="count_icon_2">
                  <ChatBubbleOutlineIcon className="count_icon" />
                </Box>
                {counterOn && <CountUp end={850} className="count_count" />}
                <Typography variant="h6" className="count_head">
                  Consulteds
                </Typography>
              </Box>
            </Grid>
            <Grid item xl={3} lg={3} md={6} sm={6} xs={12}>
              <Box
                sx={{ position: "relative" }}
                pt={{ lg: 0, md: 5, sm: 5, xs: 6 }}
              >
                <Box className="count_icon_3">
                  <LightbulbIcon className="count_icon" />
                </Box>
                {counterOn && <CountUp end={1238} className="count_count" />}
                <Typography variant="h6" className="count_head">
                  Satisfied Customers
                </Typography>
              </Box>
            </Grid>
            <Grid item xl={3} lg={3} md={6} sm={6} xs={12}>
              <Box
                sx={{ position: "relative" }}
                pt={{ lg: 0, md: 5, sm: 5, xs: 6 }}
              >
                <Box className="count_icon_4">
                  <WorkIcon className="count_icon" />
                </Box>
                {counterOn && <CountUp end={1238} className="count_count" />}
                <Typography variant="h6" className="count_head">
                  Satisfied Customers
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </ScrollTrigger>
      </Box>
      {/* Testimonial Section */}
      <Box sx={{ padding: "100px 220px" }} className="testimonial_section">
        <Box sx={{ textAlign: "center", paddingBottom: "100px" }}>
          <Typography variant="h3" className="homePageHead">
            See What Out Happy
            <Box component="br"></Box>
            <Box component="span" sx={{ color: "#c6ac82" }}>
              Clients{" "}
            </Box>{" "}
            Says
          </Typography>
        </Box>
        <Slider {...testimonial}>
          {testimonialImg.map((item, i) => {
            return (
              <Grid container maxWidth="lg" margin="auto" key={i}>
                <Grid item xl={12} lg={12} md={12} sm={12} xs={12} px={{ xl: 2 }}>
                  <Grid container sx={{ position: "relative" }}>
                    <Grid item xl={5} lg={5} md={5} sm={12} xs={12}>
                      <Box
                        component="img"
                        src={item.img}
                        sx={{
                          backgroundSize: "cover",
                          filter: "brightness(0.5)",
                          margin: "auto",
                        }}
                      ></Box>
                    </Grid>
                    <Box sx={{ position: "absolute", left: "40px" }}>
                      <FormatQuoteIcon sx={{ fontSize: 100, color: "#fff" }} />
                    </Box>
                    <Grid
                      item
                      xl={7}
                      lg={7}
                      md={7}
                      sm={12}
                      xs={12}
                      px={{ sm: 0, xs: 3 }}
                    >
                      <Box>
                        <Typography
                          variant="h6"
                          className="testimonial_card_head"
                        >
                          Nobilt Gilt
                        </Typography>
                        <Typography
                          variant="subtitle2"
                          className="testimonial_card_italic"
                        >
                          SEO Expert
                        </Typography>
                        <Typography
                          variant="body2"
                          className="testimonial_card_p"
                        >
                          sum dolor sit amet consectetur toward adipisicing elit
                          sed do eiusmod tempor incididunt ut labore et dolore
                          magna aliqua. Ut enim ad minim.
                        </Typography>
                      </Box>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            );
          })}
        </Slider>
      </Box>
      {/* Contact Section */}
      <Box sx={{ padding: "0px 0px", position: "relative" }}>
        <Box
          sx={{
            backgroundImage: `url(${AB})`,
            backgroundAttachment: "fixed",
            backgroundSize: "cover",
            display: "flex",
            padding: "40vh 0px",
            filter: "brightness(0.5)",
          }}
          className="contact_section"
        ></Box>
        <Grid
          container
          maxWidth="lg"
          sx={{
            margin: "auto",
            color: "#fff",
            position: "absolute",
            left: 0,
            right: 0,
            top: "6vh",
          }}
          px={{ lg: 0, md: 5, sm: 5 }}
        >
          <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
            <Box
              sx={{ padding: "170px 0px", position: "relative" }}
              className="img-contact-content"
              mx={{ sm: 0, xs: 4 }}
            >
              <Box className="circle">
                <CallIcon className="callAnimeIcon" />
              </Box>
              <Typography variant="h3">Free Consultion</Typography>
              <Box sx={{ paddingTop: 5 }}>
                <Box sx={{ display: "flex", cursor: "pointer" }}>
                  <LocationOnIcon
                    sx={{
                      verticalAlign: "middle",
                      paddingTop: "12px",
                      color: "#c6ac82",
                    }}
                  />
                  <Box
                    component="p"
                    sx={{ color: "#d5d5d5" }}
                    px={{ xl: 1, lg: 1, md: 1, sm: 1, xs: 1 }}
                  >
                    Address: 309, New Cavendish St, EC1Y 3WK
                  </Box>
                </Box>
                <Box sx={{ display: "flex", cursor: "pointer" }}>
                  <CallIcon
                    sx={{
                      verticalAlign: "middle",
                      paddingTop: "12px",
                      color: "#c6ac82",
                    }}
                  />
                  <Box
                    component="p"
                    sx={{ color: "#d5d5d5" }}
                    px={{ xl: 1, lg: 1, md: 1, sm: 1, xs: 1 }}
                  >
                    0400 20778972
                  </Box>
                </Box>
                <Box sx={{ display: "flex", cursor: "pointer" }}>
                  <MailOutlineIcon
                    sx={{
                      verticalAlign: "middle",
                      paddingTop: "15px",
                      color: "#c6ac82",
                    }}
                  />
                  <Box
                    component="p"
                    sx={{ color: "#d5d5d5" }}
                    px={{ xl: 1, lg: 1, md: 1, sm: 1, xs: 1 }}
                  >
                    polpo@traxagency.co.au
                  </Box>
                </Box>
              </Box>
            </Box>
          </Grid>
          <Grid item xl={6} lg={6} md={6} sm={12} xs={12} mx={{ sm: 0, xs: 3 }}>
            <Box sx={{ backgroundColor: "#fff", padding: 5 }}>
              <Box
                component="form"
                onSubmit={handleSubmit((data) => console.log(data))}
              >
                <TextField
                  label="Name"
                  variant="outlined"
                  fullWidth
                  {...register("name", {
                    required: true,
                    pattern: "/[a-z, ]/i",
                  })}
                ></TextField>
                {errors.name && (
                  <Typography variant="button" sx={{ color: "#cc3535" }}>
                    Name is required
                  </Typography>
                )}
                <TextField
                  sx={{ marginTop: 5 }}
                  label="Mail Address"
                  variant="outlined"
                  fullWidth
                  {...register("email", { required: true })}
                ></TextField>
                {errors.email && (
                  <Typography variant="button" sx={{ color: "#cc3535" }}>
                    E-Mail is required
                  </Typography>
                )}
                <TextField
                  sx={{ marginTop: 5 }}
                  label="Phone Number"
                  variant="outlined"
                  fullWidth
                  {...register("phoneNumber", {
                    required: true,
                    minLength: 10,
                    maxLength: 10,
                    pattern: {
                      value: " /[a-z, ]/i",
                      message: "Invalid Phone Number",
                    },
                  })}
                ></TextField>
                {errors.phoneNumber && (
                  <Typography variant="button" sx={{ color: "#cc3535" }}>
                    Phone Number is required
                  </Typography>
                )}
                <TextField
                  sx={{ marginTop: 5 }}
                  label="Text...."
                  variant="outlined"
                  rows={2}
                  multiline
                  fullWidth
                  {...register("message", {
                    required: true,
                  })}
                ></TextField>
                {errors.message && (
                  <Typography variant="button" sx={{ color: "#cc3535" }}>
                    Message is required
                  </Typography>
                )}
                <Button
                  variant="contained"
                  startIcon={<SendIcon />}
                  sx={{ marginTop: 5 }}
                  fullWidth
                  className="primary-bg-color form_btn"
                  type="submit"
                >
                  SUBMIT NOW
                </Button>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Box>

      {/* Arrow Circle Up Icon */}
      <ScrollToTop
        smooth
        className="scrool_to_up"
        component={
          <Box>
            <ExpandLessIcon />
          </Box>
        }
      />
    </Box>
  );
}

export default Home;
