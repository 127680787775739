import React from "react";
import "./Footer.css";
import { Box, Button, Grid, List, ListItem, Typography } from "@mui/material";
import LOGO from "../../Assets/company-logo.png";
import FooterImg from "../../Assets/bt1.jpg";
import FooterImg2 from "../../Assets/bt2.jpg";
import FooterImg3 from "../../Assets/bt3.jpg";
import FooterImg4 from "../../Assets/bt4.jpg";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
//Icons
import FacebookIcon from "@mui/icons-material/Facebook";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import TwitterIcon from "@mui/icons-material/Twitter";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import YouTubeIcon from "@mui/icons-material/YouTube";
import LogoDevIcon from "@mui/icons-material/LogoDev";

function Footer() {
  return (
    <Box sx={{ backgroundColor: "#eee" }}>
      {/* Footer */}
      <Box sx={{ padding: "100px 0px" }} px={{ lg: 0, md: 4, sm: 4, xs: 5 }}>
        <Grid container maxWidth="lg" margin="auto">
          <Grid item xl={4} lg={4} md={6} sm={12} xs={12}>
            <Box>
              <Box sx={{ position: "relative" }}>
                <Typography variant="h5" className="footer_head">
                  About Us
                </Typography>
                <Box className="dash_line"></Box>
              </Box>
              <Typography
                variant="subtitle1"
                className="footer_sub_text"
                sx={{
                  color: "#343434",
                  maxWidth: "310px",
                  lineHeight: "1.75",
                  fontSize: "15px",
                }}
              >
                Main practice areas of the firm include Admiralty, Maritime and
                Ship Arrest, Arbitration, Mediation and ADRs, Aviation Matters,
                Banking, Finance & Investment, Capital Market, Commercial
                Litigation, Commercial Transactions, International Trade,
                Construction & Engineering., Foreign Investment and Business
                Set-Up.
              </Typography>
              <Button
                sx={{ marginTop: 4, padding: "20px 40px" }}
                variant="contained"
                size="large"
                className="primary-bg-color white-color btn_animation"
              >
                Get An Appointment
              </Button>
            </Box>
          </Grid>
          <Grid
            item
            xl={4}
            lg={4}
            md={6}
            sm={12}
            xs={12}
            pt={{ md: 0, sm: 5, xs: 7 }}
          >
            <Box sx={{ position: "relative", paddingInlineStart: 2 }}>
              <Typography variant="h5" className="footer_head">
                Practice Area
              </Typography>
              <Box className="dash_line2"></Box>
            </Box>
            <Grid container className="footer_sub_text">
              <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
                <List>
                  <ListItem className="footer_sec_list1">
                    Admiralty & Maritime
                  </ListItem>
                  <ListItem className="footer_sec_list1">
                    Arbitration & ADR
                  </ListItem>
                  <ListItem className="footer_sec_list1">
                    Accounting & Finance
                  </ListItem>
                  <ListItem className="footer_sec_list1">
                    Finance & Investment
                  </ListItem>
                  <ListItem className="footer_sec_list1">
                    Criminal Prosecution
                  </ListItem>
                  <ListItem className="footer_sec_list1">
                    Land & Property
                  </ListItem>
                  <ListItem className="footer_sec_list1">
                    Auditing Problem
                  </ListItem>
                  <ListItem className="footer_sec_list1">
                    Case Solution
                  </ListItem>
                </List>
              </Grid>
              <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
                <List>
                  <ListItem className="footer_sec_list1">
                    Family Matters
                  </ListItem>
                  <ListItem className="footer_sec_list1">Social Issue</ListItem>
                  <ListItem className="footer_sec_list1">
                    Life Insurance
                  </ListItem>
                  <ListItem className="footer_sec_list1">
                    Finance & Investment
                  </ListItem>
                  <ListItem className="footer_sec_list1">
                    Business Manage
                  </ListItem>
                  <ListItem className="footer_sec_list1">Cheating</ListItem>
                  <ListItem className="footer_sec_list1">
                    Fruad Matters
                  </ListItem>
                  <ListItem className="footer_sec_list1">
                    High Court Matters
                  </ListItem>
                </List>
              </Grid>
            </Grid>
          </Grid>
          <Grid
            item
            xl={4}
            lg={4}
            md={6}
            sm={12}
            xs={12}
            pt={{ lg: 0, md: 3, sm: 5, xs: 7 }}
            sx={{ display: "grid", justifyContent: "flex-end" }}
          >
            <Box sx={{ position: "relative" }}>
              <Typography variant="h5" className="footer_head">
                News Feeds
              </Typography>
              <Box className="dash_line3"></Box>
            </Box>
            <Box
              sx={{
                display: "flex",
                width: "300px",
                color: "#555555",
                fontSize: "14px",
                fontWeight: 600,
              }}
              className="footer_newsFeed_card"
            >
              <Box sx={{ flex: "1 0 auto", margin: "auto" }}>
                <CardMedia
                  component="img"
                  src={FooterImg}
                  alt="Live from space album cover"
                />
              </Box>
              <CardContent sx={{ display: "flex", flexDirection: "column" }}>
                <Box sx={{ position: "relative" }}>
                  <CalendarMonthIcon sx={{ color: "#C6AC82" }} />
                  <Box
                    component="span"
                    sx={{ position: "absolute", fontWeight: 500 }}
                  >
                    12th June 2020
                  </Box>
                </Box>
                <Box variant="body2">
                  Managing Partner along with Senior Counsels.
                </Box>
              </CardContent>
            </Box>
            <Box
              sx={{
                display: "flex",
                width: "300px",
                color: "#555555",
                fontSize: "14px",
                fontWeight: 600,
              }}
              className="footer_newsFeed_card"
            >
              <Box sx={{ flex: "1 0 auto", margin: "auto" }}>
                <CardMedia
                  component="img"
                  src={FooterImg2}
                  alt="Live from space album cover"
                />
              </Box>
              <CardContent sx={{ display: "flex", flexDirection: "column" }}>
                <Box sx={{ position: "relative" }}>
                  <CalendarMonthIcon sx={{ color: "#C6AC82" }} />
                  <Box
                    component="span"
                    sx={{ position: "absolute", fontWeight: 500 }}
                  >
                    12th June 2020
                  </Box>
                </Box>
                <Box variant="body2">
                  Managing Partner along with Senior Counsels.
                </Box>
              </CardContent>
            </Box>
            <Box
              sx={{
                display: "flex",
                width: "300px",
                color: "#555555",
                fontSize: "14px",
                fontWeight: 600,
              }}
              className="footer_newsFeed_card"
            >
              <Box sx={{ flex: "1 0 auto", margin: "auto" }}>
                <CardMedia
                  component="img"
                  src={FooterImg3}
                  alt="Live from space album cover"
                />
              </Box>
              <CardContent sx={{ display: "flex", flexDirection: "column" }}>
                <Box sx={{ position: "relative" }}>
                  <CalendarMonthIcon sx={{ color: "#C6AC82" }} />
                  <Box
                    component="span"
                    sx={{ position: "absolute", fontWeight: 500 }}
                  >
                    12th June 2020
                  </Box>
                </Box>
                <Box variant="body2">
                  Managing Partner along with Senior Counsels.
                </Box>
              </CardContent>
            </Box>
            <Box
              sx={{
                display: "flex",
                width: "300px",
                color: "#555555",
                fontSize: "14px",
                fontWeight: 600,
              }}
              className="footer_newsFeed_card"
            >
              <Box sx={{ flex: "1 0 auto", margin: "auto" }}>
                <CardMedia
                  component="img"
                  src={FooterImg4}
                  alt="Live from space album cover"
                />
              </Box>
              <CardContent sx={{ display: "flex", flexDirection: "column" }}>
                <Box sx={{ position: "relative" }}>
                  <CalendarMonthIcon sx={{ color: "#C6AC82" }} />
                  <Box
                    component="span"
                    sx={{ position: "absolute", fontWeight: 500 }}
                  >
                    12th June 2020
                  </Box>
                </Box>
                <Box variant="body2">
                  Managing Partner along with Senior Counsels.
                </Box>
              </CardContent>
            </Box>
          </Grid>
        </Grid>
      </Box>
      <Box className="footer_sub">
        <Grid
          container
          maxWidth="lg"
          margin="auto"
          px={{ lg: 0, md: 3, sm: 3, xs: 1 }}
        >
          <Grid item xl={4} lg={4} md={4} sm={4} xs={12}>
            <List sx={{ display: "flex", gap: 2 }}>
              <ListItem className="list_item">
                <FacebookIcon className="fb_icon" />
              </ListItem>
              <ListItem className="list_item">
                <TwitterIcon className="fb_icon" />
              </ListItem>
              <ListItem className="list_item">
                <LinkedInIcon className="fb_icon" />
              </ListItem>
              <ListItem className="list_item">
                <YouTubeIcon className="fb_icon" />
              </ListItem>
              <ListItem className="list_item">
                <LogoDevIcon className="fb_icon" />
              </ListItem>
            </List>
          </Grid>
          <Grid item xl={4} lg={4} md={4} sm={4} xs={12}>
            <Box
              sx={{ display: "flex", justifyContent: "center" }}
              pt={{ sm: 0, xs: 2 }}
            >
              <Box component="img" src={LOGO} sx={{ height: "55px" }}></Box>
            </Box>
          </Grid>
          <Grid item xl={4} lg={4} md={4} sm={4} xs={12}>
            <Box sx={{ paddingTop: 2 }}>
              <Typography variant="h6" className="copyright_text">
                Copyright By @ Company - 2021
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
}

export default Footer;
